export default {
  CFG_COLOR_TESTO_BIANCO: 'White',
  CFG_COLOR_TESTO_GIALLO: 'Yellow',
  CFG_COLOR_TESTO_ARANCIO: 'Orange',
  CFG_COLOR_TESTO_ROSSO: 'Red',
  CFG_COLOR_TESTO_CELESTE: 'Light Blue',
  CFG_COLOR_TESTO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_TESTO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_TESTO_ARGENTO: 'Silver',
  CFG_COLOR_TESTO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_TESTO_NERO: 'Black',
  CFG_COLOR_TESTO_BLU_ELETTRICO: 'Electric Blue',

  CFG_COLOR_POLSICAVIGLIE_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_POLSICAVIGLIE_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_POLSICAVIGLIE_NERO: 'Black',
  CFG_COLOR_POLSICAVIGLIE_ROSSO: 'Red',
  CFG_COLOR_POLSICAVIGLIE_BIANCO: 'White',
  CFG_COLOR_POLSICAVIGLIE_GRIGIO_423C: 'Grey 423 C',

  CFG_COLOR_SUPERSPEED_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_SUPERSPEED_PRIMARIO_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_SUPERSPEED_PRIMARIO_NERO: 'Black',
  CFG_COLOR_SUPERSPEED_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Orange Fluo',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_CELESTE: 'Light Blue',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_METAL: 'Metal Green',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SUPERSPEED_JERSEY_BIANCO: 'White',
  CFG_COLOR_SUPERSPEED_JERSEY_GRIGIO_NEBBIA: 'Fog Grey',
  CFG_COLOR_SUPERSPEED_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SUPERSPEED_JERSEY_NERO: 'Black',

  CFG_COLOR_SPRINT_PRIMARIO_AZZURRO: 'Light Blue',
  CFG_COLOR_SPRINT_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_SPRINT_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_SPRINT_PRIMARIO_GIALLO_FLUO: 'Yellow Fluo',
  CFG_COLOR_SPRINT_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SPRINT_PRIMARIO_NERO: 'Black',
  CFG_COLOR_SPRINT_PRIMARIO_PINK: 'Pink',
  CFG_COLOR_SPRINT_PRIMARIO_ROSSO_CITROEN: 'Citroen Red',
  CFG_COLOR_SPRINT_PRIMARIO_VERDE_FLUO: 'Green Fluo',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Orange Fluo',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_CELESTE: 'Light Blue',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SPRINT_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_SPRINT_JERSEY_AZZURRO: 'Light Blue',
  CFG_COLOR_SPRINT_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_SPRINT_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SPRINT_JERSEY_NERO: 'Black',
  CFG_COLOR_SPRINT_JERSEY_ROSSO: 'Red',
  CFG_COLOR_SPRINT_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SPRINT_JERSEY_BIANCO: 'White',

  CFG_COLOR_PRIME_PRIMARIO_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_PRIME_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_PRIME_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_PRIME_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_PRIME_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_PRIME_PRIMARIO_GIALLO: 'Yellow',
  CFG_COLOR_PRIME_PRIMARIO_ANTRACITE: 'Anthracite',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_PRIME_PRIMARIO_NERO: 'Black',
  CFG_COLOR_PRIME_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_PRIME_PRIMARIO_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_PRIME_PRIMARIO_VERDE_AMR: 'AMR Green',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Orange Fluo',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VERDE_METAL: 'Metal Green',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_PRIME_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_PRIME_JERSEY_AZZURRO: 'Light Blue',
  CFG_COLOR_PRIME_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_PRIME_JERSEY_NERO: 'Black',
  CFG_COLOR_PRIME_JERSEY_ROSSO: 'Red',
  CFG_COLOR_PRIME_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_PRIME_JERSEY_BIANCO: 'White',
  CFG_COLOR_PRIME_PRIMARIO_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_7545C: 'Grey 7545 C',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_GIALLO_CEDRO: 'Cedar Yellow',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Vodafone Red',

  CFG_COLOR_PRIME_2022_PRIMARIO_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_PRIME_2022_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_PRIME_2022_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_GIALLO: 'Yellow',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_ANTRACITE: 'Anthracite Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_NERO: 'Black',
  CFG_COLOR_PRIME_2022_PRIMARIO_ROSSO_SCURO: 'Dark Red',
  CFG_COLOR_PRIME_2022_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_PRIME_2022_PRIMARIO_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_PRIME_2022_PRIMARIO_VERDE_AMR: 'AMR Green',
  CFG_COLOR_PRIME_2022_PRIMARIO_METAL_GREEN: 'Metal Green',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_CELESTE: 'Light Blue',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GIALLO: 'Yellow',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_ANTRACITE: 'Anthracite Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_NERO: 'Black',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ROSSO_SCURO: 'Dark Red',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ROSSO: 'Red',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_VERDE_AMR: 'AMR Green',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_METAL_GREEN: 'Metal Green',
  CFG_COLOR_PRIME_2022_JERSEY1_AZZURRO: 'Light Blue',
  CFG_COLOR_PRIME_2022_JERSEY1_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_PRIME_2022_JERSEY1_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_PRIME_2022_JERSEY1_NERO: 'Black',
  CFG_COLOR_PRIME_2022_JERSEY1_ROSSO: 'Red',
  CFG_COLOR_PRIME_2022_JERSEY1_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_PRIME_2022_JERSEY1_BIANCO: 'White',
  CFG_COLOR_PRIME_2022_JERSEY2_BIANCO: 'White',
  CFG_COLOR_PRIME_2022_JERSEY2_NERO: 'Black',
  CFG_COLOR_PRIME_2022_JERSEY2_AZZURRO: 'Light Blue',

  CFG_COLOR_COMPETITION_PRIMARIO_ARANCIO_FLUO: 'Orange Fluo',
  CFG_COLOR_COMPETITION_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_COMPETITION_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_COMPETITION_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_COMPETITION_PRIMARIO_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_COMPETITION_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_COMPETITION_PRIMARIO_FUCSIA: 'Fucsia',
  CFG_COLOR_COMPETITION_PRIMARIO_GIALLO: 'Yellow',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_METAL: 'Metal Green',
  CFG_COLOR_COMPETITION_PRIMARIO_NERO: 'Black',
  CFG_COLOR_COMPETITION_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_COMPETITION_PRIMARIO_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_COMPETITION_PRIMARIO_GIALLO_LIMONE: 'Lemon yellow',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_431C: 'Grey 431 C',
  CFG_COLOR_COMPETITION_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_COMPETITION_JERSEY_AZZURRO: 'Sparco Blue',
  CFG_COLOR_COMPETITION_JERSEY_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_COMPETITION_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_COMPETITION_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_COMPETITION_JERSEY_NERO: 'Black',
  CFG_COLOR_COMPETITION_JERSEY_ROSSO: 'Red',
  CFG_COLOR_COMPETITION_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_COMPETITION_JERSEY_BIANCO: 'White',

  CFG_COLOR_COMPETITION_2022_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_ARANCIONE: 'Orange',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GIALLO_SOLE: 'Sun Yellow',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_NERO: 'Black',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VIOLA: 'Purple',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE: 'Green',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE_ACQUA: 'Aqua Green',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE_PISTACCHIO: 'Pistachio Green',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_GRIGIO_MEDIO: 'Medium Grey',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_ROSA: 'Pink',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_VERDE_FLUO: 'Fluo Green',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_BIANCO: 'White',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_REFLEX_BLUE: 'Reflex Blue',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_NERO: 'Black',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_ROSSO: 'Red',
  CFG_COLOR_COMPETITION_2022_JERSEY_AZZURRO: 'Light Blue',
  CFG_COLOR_COMPETITION_2022_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_COMPETITION_2022_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_COMPETITION_2022_POLSI_NERO: 'Black',

  CFG_COLOR_VICTORY_PRIMARIO_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_VICTORY_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_VICTORY_PRIMARIO_NERO: 'Black',
  CFG_COLOR_VICTORY_PRIMARIO_GRIGIO_431C: 'Grey 431 C',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GIALLO_LIMONE: 'Lemon yellow',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_ROSSO: 'Red',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Orange Fluo',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_CELESTE: 'Light Blue',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_METAL: 'Metal Green',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_VICTORY_JERSEY_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_VICTORY_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_VICTORY_JERSEY_AZZURRO: 'Light Blue',
  CFG_COLOR_VICTORY_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_VICTORY_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_VICTORY_JERSEY_NERO: 'Black',
  CFG_COLOR_VICTORY_JERSEY_ROSSO: 'Red',
  CFG_COLOR_VICTORY_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_VICTORY_JERSEY_BIANCO: 'White',

  CFG_COLOR_SPRINT2020_PRIMARIO_BLU_ELETTRICO: 'Electric Blue',
  CFG_COLOR_SPRINT2020_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SPRINT2020_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_SPRINT2020_PRIMARIO_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_SPRINT2020_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SPRINT2020_PRIMARIO_NERO: 'Black',
  CFG_COLOR_SPRINT2020_PRIMARIO_PINK: 'Pink',
  CFG_COLOR_SPRINT2020_PRIMARIO_ROSSO_CITROEN: 'Citroen Red',
  CFG_COLOR_SPRINT2020_PRIMARIO_VERDE_FLUO: 'Fluo Green',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Fluo Orange',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_CELESTE: 'Light Blue',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_431C: 'Grey 431 C',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Vodafone Red',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SPRINT2020_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_SPRINT2020_JERSEY_AZZURRO: 'Sparco Blue',
  CFG_COLOR_SPRINT2020_JERSEY_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_SPRINT2020_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_SPRINT2020_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SPRINT2020_JERSEY_NERO: 'Black',
  CFG_COLOR_SPRINT2020_JERSEY_ROSSO: 'Red',
  CFG_COLOR_SPRINT2020_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SPRINT2020_JERSEY_BIANCO: 'White',

  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_CELESTE: 'Light Blue',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GIALLO: 'Yellow',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_ANTRACITE: 'Anthracite Grey',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ROSSO_SCURO: 'Dark Red',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_VERDE_AMR: 'AMR Green',

  CFG_COLOR_XLIGHT_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_XLIGHT_PRIMARIO_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_XLIGHT_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_XLIGHT_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_XLIGHT_PRIMARIO_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_XLIGHT_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_XLIGHT_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_XLIGHT_PRIMARIO_GIALLO: 'Yellow',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_ANTRACITE: 'Anthracite Grey',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_7545C: 'Grey 7525 C',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_XLIGHT_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_XLIGHT_PRIMARIO_VERDE_LOTUS: 'Lotus Green',
  CFG_COLOR_XLIGHT_PRIMARIO_VERDE_AMR: 'AMR Green',
  CFG_COLOR_XLIGHT_PRIMARIO_NERO: 'Black',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Fluo Orange',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_GIALLO_CEDRO: 'Cedar Yellow',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Vodafone Red',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VERDE_METAL: 'Metal Green',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_XLIGHT_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_XLIGHT_JERSEY_AZZURRO: 'Light Blue',
  CFG_COLOR_XLIGHT_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_XLIGHT_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_XLIGHT_JERSEY_NERO: 'Black',
  CFG_COLOR_XLIGHT_JERSEY_CHAMATEX_NERO: 'Black Chamatex',
  CFG_COLOR_XLIGHT_JERSEY_ROSSO: 'Red',
  CFG_COLOR_XLIGHT_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_XLIGHT_JERSEY_BIANCO: 'White',
  CFG_COLOR_XLIGHT_JERSEY_CHAMATEX_BIANCO: 'White Chamatex',
  CFG_COLOR_XLIGHT_JERSEY_LULO_BIANCO: 'White Lulo',
  CFG_COLOR_XLIGHT_JERSEY_LULO_NERO: 'Black Lulo',

  CFG_COLOR_XLIGHT_KART_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_BLU_ELETTRICO: 'Electric Blue',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_CG1C: 'Grey CG 1 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_431C: 'Grey 431 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_7546C: 'Grey 7546 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_NERO: 'Black',
  CFG_COLOR_XLIGHT_KART_SECONDARIO_ARANCIO_FLUO: 'Fluo Orange',
  CFG_COLOR_XLIGHT_KART_SECONDARIO_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_BIANCO: 'White',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_BLU_SCURO: 'Dark Blue',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_ROSSO: 'Red',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_VERDE_TONY_KART: 'Tony Kart Green',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_LONDON_SMOKE: 'London Smoke',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_NERO: 'Black',

  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ARANCIO_166C: 'Orange 166 C',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Flame Orange',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_AZZURRO_SPARCO: 'Sparco Light Blue',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BLU_CHIARO: 'Sky Blue',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BIANCO_OTTICO: 'Optic White',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GIALLO: 'Yellow',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_ANTRACITE: 'Anthracite Grey',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_NERO: 'Black',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ROSSO_SCURO: 'Dark Red',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ROSSO: 'Red',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_VERDE_AMR: 'AMR Green',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_METAL_GREEN: 'Metal Green',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_AZZURRO: 'Light Blue',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_NERO: 'Black',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_ROSSO: 'Red',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_BIANCO: 'White',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_BIANCO: 'White',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_NERO: 'Black',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_AZZURRO: 'Light Blue',

  CFG_COLOR_FUTURA_2025_PRIMARIO_BLU_ELETTRICO: 'Electric Blue',
  CFG_COLOR_FUTURA_2025_PRIMARIO_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_FUTURA_2025_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_FUTURA_2025_PRIMARIO_GIALLO_FLUO: 'Fluo Yellow',
  CFG_COLOR_FUTURA_2025_PRIMARIO_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_FUTURA_2025_PRIMARIO_NERO: 'Black',
  CFG_COLOR_FUTURA_2025_PRIMARIO_PINK: 'Pink',
  CFG_COLOR_FUTURA_2025_PRIMARIO_ROSSO_CITROEN: 'Citroen Red',
  CFG_COLOR_FUTURA_2025_PRIMARIO_VERDE_FLUO: 'Fluo Green',
  CFG_COLOR_FUTURA_2025_PRIMARIO_ARANCIONE: 'Orange',
  CFG_COLOR_FUTURA_2025_PRIMARIO_CELESTE: 'Light Blue',
  CFG_COLOR_FUTURA_2025_PRIMARIO_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_FUTURA_2025_PRIMARIO_GIALLO_SOLE: 'Sun Yellow',
  CFG_COLOR_FUTURA_2025_PRIMARIO_GRIGIO_SCURO: 'Dark Grey',
  CFG_COLOR_FUTURA_2025_PRIMARIO_VIOLA: 'Purple',
  CFG_COLOR_FUTURA_2025_PRIMARIO_VERDE: 'Green',
  CFG_COLOR_FUTURA_2025_PRIMARIO_VERDE_ACQUA: 'Aqua Green',
  CFG_COLOR_FUTURA_2025_PRIMARIO_VERDE_PISTACCHIO: 'Pistachio Green',
  CFG_COLOR_FUTURA_2025_JERSEY_BLU_REFLEX: 'Reflex Blue',
  CFG_COLOR_FUTURA_2025_JERSEY_GRIGIO_CHIARO: 'Light Grey',
  CFG_COLOR_FUTURA_2025_JERSEY_GRIGIO_423C: 'Grey 423 C',
  CFG_COLOR_FUTURA_2025_JERSEY_NERO: 'Black',
  CFG_COLOR_FUTURA_2025_JERSEY_ROSSO: 'Red',
  CFG_COLOR_FUTURA_2025_JERSEY_VERDE_BANDIERA: 'Flag Green',
  CFG_COLOR_FUTURA_2025_JERSEY_BIANCO: 'White',
  CFG_COLOR_FUTURA_2025_TESSUTO_RIGENERATO: 'Regenerated Fabric',
  CFG_COLOR_FUTURA_2025_JERSEY_ARANCIO: 'Orange',
  CFG_COLOR_FUTURA_2025_JERSEY_AZZURRO: 'Blue',
  CFG_COLOR_FUTURA_2025_JERSEY_BLU_MARINE: 'Marine Blue',
  CFG_COLOR_FUTURA_2025_JERSEY_GIALLO: 'Yellow',
  CFG_COLOR_FUTURA_2025_JERSEY_GRIGIO_SCURO: 'Dark gray',

  CFG_COLOR_PRIME_RW10_PRIMARIO_BIANCO: 'White',
  CFG_COLOR_PRIME_RW10_PRIMARIO_NERO: 'Black',

  MODEL_DESCRIPTION_001091SP:
    'The Sprint RS-2.1 racing suit is aimed at all motorsport enthusiasts. Advanced in every detail and in terms of material quality, which has reached the highest level, the Sprint RS-2.1 racing suit comes with mesh panels at the armpits and back. The Sprint RS-1 racing suit in meta-aramid is made up of a 2-layer sandwich, the average weight of which ranges between 360 and 390 g/m².',
  MODEL_DESCRIPTION_0011279SP:
    'A high-range suit, made of a medium-light slim compound, but in particular with a new outer fabric partly made of high-range natural fibres such as silk. (natural fibre, pleasant to the touch with a slightly shiny look). The suit was designed with the driver’s comfort when driving, with elasticised panels made of Eagle fabric (complete armholes, sides, kneecap leg panels and back), preformed sleeves. The suit is equipped with contrasting panels in flat ultralight cloth and new Sparco personalised linings, with some details in partial view, producing the characteristic Sparco style.',
  MODEL_DESCRIPTION_001133SP:
    'The Prime SP-16 suit was designed to provide drivers light weight material, comfort and breathability. Even the smallest details in terms of materials, construction and accessories were taken to the limits to ensure weight optimization down to the very last gram. COMPOSITION: Meta-aramid/polyamide-imide. Three-layer sandwich weighing less than 240/270 g/m².',
  MODEL_DESCRIPTION_001134SP:
    'The brand new Competition suit offers latest-generation quality and style standards. Its configuration includes a soft and lightweight outer fabric, breathable inserts at the sides and under the arms, a lightweight collar, ultra-lightweight seams, pre-shaped sleeves, an adjustable belt and hidden pockets. The new clean and linear, non-quilted design gives dynamism and enhances the figure of anyone wearing the suit. Composition : 95% Meta-Aramid / 5% Para-Aramid. 3-layer sandwich with an average total weight of 300/330 g/m².',
  MODEL_DESCRIPTION_001128SP:
    'The brand new Competition suit offers latest-generation quality and style standards. Its configuration includes a soft and lightweight outer fabric, breathable inserts at the sides and under the arms, a lightweight collar, ultra-lightweight seams, pre-shaped sleeves, an adjustable belt and hidden pockets. The new clean and linear, non-quilted design gives dynamism and enhances the figure of anyone wearing the suit. Composition : 95% Meta-Aramid / 5% Para-Aramid. 3-layer sandwich with an average total weight of 300/330 g/m².',
  MODEL_DESCRIPTION_0011277HSP:
    'Made of single-layer Hocotex fabric, the suit was designed with the aim of ensuring maximum driver comfort when driving, with elasticised panels made of double-fronted soft mesh (armholes and back), preformed sleeves.  The suit is equipped with contrasting panels in flat ultralight cloth and new Sparco personalised linings, with some details in partial view, producing the characteristic Sparco style.<br/><br/>Stitched quilting with ultra-thin yarn evokes the classic Sparco style, integrated extraction epaulettes made in the classic Sparco shoulder design, concealed pockets, preformed neck and belt with the characteristic Sparco logo.',
  MODEL_DESCRIPTION_001092SP:
    'The Sprint racing suit is aimed at all motorsport enthusiasts. Advanced in every detail and in terms of material quality, which has reached the highest level, the 2 layer Sprint racing suit comes with  constructed using diagonal box quilt stitching from a softer, more breathable semi sheen fabric for better outer finish, half floating sleeves, antistatic lining and an elasticated stretch back panel, front pockets, adjustable belt.<br><br>FIA 8856-2000 approved & SFI3.2A/5',
  MODEL_DESCRIPTION_001093SP:
    'The Sprint racing suit is aimed at all motorsport enthusiasts. Advanced in every detail and in terms of material quality, which has reached the highest level, the 2 layer Sprint racing suit comes with constructed using diagonal box quilt stitching from a softer, more breathable semi sheen fabric for better outer finish, half floating sleeves, antistatic lining and an elasticated stretch back panel, front pockets, adjustable belt.<br><br>FIA 8856-2018 approved',
  MODEL_DESCRIPTION_001137SP:
    'The brand new Competition suit offers latest-generation quality and style standards. Its configuration includes a soft and lightweight outer fabric, breathable inserts at the sides and under the arms, a lightweight collar, ultra-lightweight seams, pre-shaped sleeves, an adjustable belt and hidden pockets. The new clean and linear, non-quilted design gives dynamism and enhances the figure of anyone wearing the suit. Composition : 95% Meta-Aramid / 5% Para-Aramid. 3-layer sandwich with an average total weight of 330/360 g/m².',
  MODEL_DESCRIPTION_001135HSP:
    'Made of single-layer Hocotex fabric, the suit was designed with the aim of ensuring maximum driver comfort when driving, with elasticised panels made of double-fronted soft mesh (armholes and back), preformed sleeves.  The suit is equipped with contrasting panels in flat ultralight cloth and new Sparco personalised linings, with some details in partial view, producing the characteristic Sparco style.<br/><br/>Stitched quilting with ultra-thin yarn evokes the classic Sparco style, integrated extraction epaulettes made in the classic Sparco shoulder design, concealed pockets, preformed neck and belt with the characteristic Sparco logo.',
  MODEL_DESCRIPTION_001165SP:
    'A high-range suit, this ultra-lightweight 3-layer suit carries all the high specification attributes you would expect, these include fully breathable lateral inserts, inner thigh inserts, fully floating pre-curved sleeves, anti-abrasion elasticated lower back panel and anti-abrasion inserts on the elbows. The suit also carries extra flat internal seams, seamless cuffs and ankles, hidden pockets and belt. FIA 8856-2000 approved.',
  MODEL_DESCRIPTION_002339SP:
    'The iconic Sparco X-Light kart suit is a 2 layer construction which offers high levels of protection whilst remaining ultra lightweight. The arms are pre-shaped to reduce material bunching in the driving position and are fully floating to allow for maximum movement.<ul><li>weight : 370/400 g/m2</li><li>elasticated anti abrasion back panel</li><li>ergonomic impact-absorbing padding (on the outside elbow and inside knee)</li><li>breathable insert on inside leg and underarm</li><li>anti-abrasion inserts (on the outside elbow and inside knee)</li><li>rounded collar</li><li>hidden hip pockets.</li></ul>The Sparco X-Light kart suit is the winning choice of many famous Champions and for the most demanding drivers<br><br>CIK FIA Level 2 approved',
  MODEL_DESCRIPTION_001139SP:
    'The new Competition Pro  suit represents a further evolution of the highly appreciated Competition range. . Its configuration includes a soft and lightweight outer fabric, breathable inserts at the sides and under the arms, a lightweight collar, ultra-lightweight seams, pre-shaped sleeves, an adjustable belt and hidden pockets. The new clean and linear, non-quilted design gives dynamism and enhances the figure of anyone wearing the suit. Composition : 95% Meta-Aramid / 5% Para-Aramid. 3-layer sandwich with an average total weight of 330/360 g/m²',
  MODEL_DESCRIPTION_001142SP:
    '<ul><li>Zip ultraleggera a doppio scorrimento</li><li>Cuciture extrapiatte</li><li>Polsini e caviglie seamless</li><li>Girello completo elasticizzato</li><li>Pannello posteriore elasticizzato</li><li>Inserti elasticizzati sui gomiti</li><li>Inserti elasticizzati sui fianchi</li><li>Inserto interno coscia elasticizzato</li><li>Tasche inserite nei fianchi</li></ul>FIA 8856-2018 approved',
  MODEL_DESCRIPTION_001144SP:
    '<ul><li>New inner thigh elastic insert</li><li>Updated with 8856-2018 approval</li><li>New seamless elastic sleeve for maximum connection between suit and gloves</li></ul>',
  MODEL_DESCRIPTION_001146SP:
    '<ul><li>Ultra-light double-sliding zip</li><li>Ultra-thin collar</li><li>Full stretch bodice</li><li>New inner thigh stretch insert</li><li>New rear stretch panel</li><li>Extra-flat seams</li><li>Seamless cuffs and ankles</li><li>FIA 8856-2018</li></ul>',
  MODEL_DESCRIPTION_001784P:
    '<ul><li>Totally seamless structure</li><li>Extra-flat seams</li><li>Ultra-light construction</li><li>Refreshing and anti-odour X-COOL treatment</li><li>Transfer print decorations</li><li>Material: 96% meta-aramide, 4% elastane</li><li>FIA 8856-2018</li></ul>',
  MODEL_DESCRIPTION_001789MSP:
    '<ul><li>Totally seamless structure</li><li>Extra-flat seams</li><li>Ultra-light construction</li><li>Refreshing and anti-odour X-COOL treatment</li><li>Transfer print decorations</li><li>Material: 48% meta-aramide, 48% modal viscose, 4% elastane</li><li>FIA 8856-2018</li></ul>',
  MODEL_DESCRIPTION_001155SP:
    '<ul><li>Extraction handle made in regenerated Full EFFICIENCY material</li><li>Mid layer and lining made with FULL EFFCICIENCY regenerated fabric</li><li>Reinforcements in Sparco Full Efficiency regenerated fabric – Inner thigh stretch insert</li><li>Extra flat seams</li><li>Seamless cuffs and ankles</li><li>FIA 8856-2018</li></ul>'
}
